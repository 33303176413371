import axios from 'axios'

const base = `${window.location.protocol}//${window.location.hostname}`
const backendURL = process.env.VUE_APP_BACKEND_URL

axios.defaults.baseURL = backendURL ? backendURL + 'api/v1' : '/'
axios.interceptors.request.use((request) => {
  const token = localStorage.getItem('token')
  if (token) {
    request.headers.Authorization = `Token ${token}`
  }
  const CSRFToken = getCookie('csrftoken')
  if (CSRFToken && (request.url?.indexOf(window.location.origin) === 0 || `${base}${request.baseURL}`?.indexOf(window.location.origin) === 0)) {
    request.headers['X-CSRFToken'] = CSRFToken
  }
  return request
})

function getCookie (name: string) {
  let cookieValue = null
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}
