import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import '@/assets/main.scss'

import router from './router'

import { createPinia } from 'pinia'

import '@/utils/axios'

import moment from 'moment/moment'
import 'moment/locale/ru'

moment().locale('ru')

const pinia = createPinia()

const app = createApp(App)

app
  .use(pinia)
  .use(router)
  .use(ElementPlus)
  .mount('#app')
