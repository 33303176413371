import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import useUserStore from '@/store/useUserStore'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    beforeEnter: () => {
      if (localStorage.getItem('token')) {
        return { name: 'index' }
      }
    }
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/rq-graph/index.vue'),
    meta: { needAuth: true }
  },
  {
    path: '/rq-graph/:id',
    name: 'rq-graph',
    component: () => import('../views/rq-graph/[id].vue'),
    meta: { needAuth: true }
  },
  {
    path: '/auth-via-jwt',
    name: 'auth-via-jwt',
    component: () => import('../views/auth-via-jwt.vue')
  },
  {
    path: '/yandex-oauth',
    name: 'yandex-oauth',
    component: () => import('../views/yandex-oauth.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (route) => {
  const userStore = useUserStore()

  if (route.meta.needAuth === true) {
    try {
      await userStore.fetchProjects()
    } catch (e: any) {
      if (e.response.status === 401) {
        userStore.logout()
        return '/login'
      }
    }
  }

  if (!localStorage.getItem('token') && !['login', 'yandex-oauth'].includes(String(route.name))) {
    return { name: 'login' }
  }
})

export default router
