<template>
  <div v-if="isAppReady">
    <RouterView />
  </div>
</template>

<style lang="scss">
</style>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import useThemeStore from '@/store/useThemeStore'
import useHotkeysStore from './store/useHotkeysStore'

/* INIT */
const themeStore = useThemeStore()
const hotkeysStore = useHotkeysStore()

/* DATA */
const isAppReady = ref(false)

onBeforeMount(async () => {
  try {
    await themeStore.fetchStyles()
    await hotkeysStore.fetchHotkeys()
  } catch (e) {
  } finally {
    isAppReady.value = true
  }
})
</script>
