export const OPERATION_TYPE_CODES = [
  'createRequirement',
  'createTask',
  'deleteRequirement',
  'deleteTask'
] as const

export type OperationType = (typeof OPERATION_TYPE_CODES)[number]

export interface IKeyData {
  code: string
  key: string
}

export type Hotkeys = Record<OperationType, IKeyData[]>
export type OperationTypeDict = Record<OperationType, string>

export const OPERATION_TYPES_DICT: OperationTypeDict = {
  createRequirement: 'Создать требование',
  createTask: 'Создать задачу',
  deleteRequirement: 'Удалить требование',
  deleteTask: 'Удалить задачу'
}

export const OPERATION_TYPES = Object.entries(OPERATION_TYPES_DICT).map(([type, name]) => {
  return {
    type: type as OperationType,
    name
  }
})

export interface HotkeysListenerData {
  type: OperationType
  operation: any
}

export default class HotkeysListener {
  static instance: HotkeysListener
  /**
   * Набор комбинаций горячих клавиш
   */
  hotkeys: Hotkeys

  /**
   * Массив нажатых клавиш для сравнения с существующей комбинацией
   */
  sequence: string[] = []

  /**
   * Массив вызываемых методов в соотвествии с комбинацией
   */
  listen: HotkeysListenerData[] = []

  /**
   * Id для очистки setTimeout
   */
  garbageId: number | null = null

  needStopListener = false

  constructor (hotkeys: Hotkeys) {
    this.hotkeys = hotkeys
  }

  setListener (listen: HotkeysListenerData[]) {
    this.listen = [...listen]

    window.addEventListener('keydown', (evt) => {
      if (this.needStopListener) {
        return
      }

      this.track(evt.code, true)
    })
    window.addEventListener('keyup', (evt) => {
      if (this.needStopListener) {
        return
      }

      this.track(evt.code, false)
    })
  }

  track (keyCode: string, isKeyDown: boolean) {
    if (this.garbageId !== null) {
      clearTimeout(this.garbageId)
    }

    if (isKeyDown) {
      if (!this.sequence.includes(keyCode)) {
        this.sequence.push(keyCode)
      }
    } else {
      const idx = this.sequence.indexOf(keyCode)
      if (idx !== -1) { this.sequence.splice(idx, 1) }
    }

    this.listen.forEach((el) => {
      if (this.sequence.length !== 0) {
        // получаем массив с кодами клавиш в зависимости от типа операции
        const keyCodesSequence = this.hotkeys?.[el.type]?.map(key => key.code)

        if (this.sequence?.toString() === keyCodesSequence?.toString()) {
          el.operation()
          this.sequence = []
        } else {
          this.garbageId = setTimeout(this.clean, 600)
        }
      }
    })
  }

  clean () {
    this.garbageId = null
    this.sequence = []
  }
}
