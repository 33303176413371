import axios from 'axios'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export interface IProject {
  id: number
  name: string
}
export default defineStore('projects', () => {
  /* DATA */
  const search = ref('')
  const projects = ref<IProject[]>([])

  /* COMPUTED */
  const filteredProjects = computed<IProject[]>(() => {
    return projects.value.filter((i: IProject) => {
      return i.name.toLowerCase().includes(search.value.toLowerCase())
    })
  })

  /* METHODS */
  async function fetchProjects () {
    try {
      const res = await axios.get('/storage/projects/')

      projects.value = (res.data || []).map((i: any) => ({
        id: i.id,
        name: i.name
      }))
    } catch (e) {
    }
  }

  return {
    search,
    projects,
    filteredProjects,

    fetchProjects
  }
})
