import { defineStore } from 'pinia'
import axios from 'axios'
import useProjectsStore from './useProjectsStore'
import { ref, watch } from 'vue'

export default defineStore('auth', () => {
  const projectsStore = useProjectsStore()

  const oauthToken = ref('')
  const oauthErrorMessage = ref('')

  async function login (login: string, password: string) {
    const res = await axios.post('/get_token/', {
      username: login,
      password
    })

    localStorage.setItem('token', res.data.token)
  }

  function logout () {
    localStorage.removeItem('token')
    window.location.href = '/login'
  }

  async function oauthLogin (token: string) {
    const channel = new BroadcastChannel('oauthErrorMessage')

    try {
      if (!token) {
        throw new Error('Не удалось получть токен!')
      }

      const res = await axios.post('/auth/ya-oauth/ ', {}, {
        headers: { Authorization: `OAuth ${token}` }
      })

      oauthToken.value = res.data.token

      if (!oauthToken.value) {
        throw new Error('Не удалось получть токен!')
      }

      localStorage.setItem('token', oauthToken.value)
    } catch (error: any) {
      if (error.response && error.response.data) {
        oauthErrorMessage.value = JSON.stringify(error.response.data)
      } else if (error.message) {
        oauthErrorMessage.value = error.message
      }

      channel.postMessage(oauthErrorMessage.value)
    } finally {
      window.close()
      channel.close()
    }
  }

  async function fetchProjects () {
    try {
      await projectsStore.fetchProjects()
    } catch (e) {
    }
  }

  watch(oauthToken, (token) => {
    if (token) {
      window.location.href = '/'
    }
  })

  window.addEventListener('storage', (event) => {
    if (event.storageArea?.getItem('token')) {
      window.location.href = '/'
    }
  })

  return {
    oauthToken,
    oauthErrorMessage,

    login,
    oauthLogin,
    logout,
    fetchProjects
  }
})
