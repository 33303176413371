import { reactive, watch } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'
import { IButtonTheme, INodeTheme } from '@/types/Theme'

export default defineStore('theme', () => {
  /* DATA */
  const buttonColors = reactive({
    primary: {
      background: '#79bbff'
    },
    success: {
      background: '#67c23a'
    },
    danger: {
      background: '#f56c6c'
    }
  })
  const nodeTheme = reactive({
    Requirement: {
      border: '#cccc00',
      background: '#ffff66',
      highlight: {
        border: '#cccc00',
        background: '#ffff66'
      }
    },
    DevTask: {
      border: '#0044cc',
      background: '#6699ff',
      highlight: {
        border: '#0044cc',
        background: '#6699ff'
      }
    },
    FrontTask: {
      border: '#ff33bb',
      background: '#ff66cc',
      highlight: {
        border: '#ff33bb',
        background: '#ff66cc'
      }
    },
    TestTask: {
      border: '#00cc44',
      background: '#66ff99',
      highlight: {
        border: '#00cc44',
        background: '#66ff99'
      }
    },
    DataTask: {
      border: '#4400cc',
      background: '#9966ff',
      highlight: {
        border: '#4400cc',
        background: '#9966ff'
      }
    },
    AutotestTask: {
      border: '#007399',
      background: '#0099cc',
      highlight: {
        border: '#007399',
        background: '#0099cc'
      }
    }
  })

  /* METHODS */
  function processButtonsStyle (styles: { [key: string]: IButtonTheme }) {
    for (const btnType in styles) {
      // @ts-ignore
      const buttonFront = buttonColors[btnType]
      const buttonColorFromBackend = styles[btnType].background

      if (!buttonFront || !buttonColorFromBackend) { continue }

      buttonFront.background = buttonColorFromBackend
    }
  }

  function processNodeTheme (themeFromBackend: { [key: string]: INodeTheme }) {
    for (const key in themeFromBackend) {
      if (!(key in nodeTheme)) { continue }
      const themeFrontend = nodeTheme[key as keyof typeof nodeTheme]

      if (themeFromBackend?.[key]?.background) {
        themeFrontend.background = themeFromBackend[key].background
      }
      if (themeFromBackend?.[key]?.border) {
        themeFrontend.border = themeFromBackend[key].border
      }
      if (themeFromBackend?.[key]?.highlight?.background) {
        themeFrontend.highlight.background = themeFromBackend[key].highlight.background
      }
      if (themeFromBackend?.[key]?.highlight?.border) {
        themeFrontend.highlight.border = themeFromBackend[key].highlight.border
      }
    }
  }

  async function fetchStyles () {
    try {
      const { nodeStyles, buttonsStyle } = await axios.get('/styles/')
        .then(r => ({
          nodeStyles: r.data?.style_nodes,
          buttonsStyle: r.data?.buttons
        }))

      nodeStyles && processNodeTheme(nodeStyles)
      buttonsStyle && processButtonsStyle(buttonsStyle)
    } catch (e) {
    }
  }

  let st: HTMLStyleElement | undefined
  watch(
    nodeTheme,
    () => {
      if (!st) {
        st = document.createElement('style')
        document.body.append(st)
      }

      st.innerHTML = Object.entries(nodeTheme).map(item => {
        const key = item[0]
        const value = item[1]

        return `
          .d3-node[data-type-node="${key}"] rect:not(.d3-node-flag),
          .d3-node-task[data-type-node="${key}"] {
            fill: ${value.background};
            stroke-width: 1px;
            stroke: ${value.border};
          }
          .d3-node[data-type-node="${key}"].active rect:not(.d3-node-flag) {
            fill: ${value.highlight.background} !important;
            stroke: ${value.highlight.border} !important;
          }
          .d3-node[data-is-dummy-node="true"] rect:not(.d3-node-flag) {
            fill: #ffffcc !important;
          }
        `
      }).join(' ')
    },
    { deep: true, immediate: true }
  )

  return {
    buttonColors,
    nodeTheme,

    fetchStyles
  }
})
